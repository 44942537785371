<template>
  <div class="base-list-page">
    <el-form
      :model="dataForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-select
            v-model="dataForm.orgId"
            placeholder="供应商"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select
            v-model="dataForm.companyId"
            placeholder="公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-select
            v-model="dataForm.freezerId"
            placeholder="智能取餐柜"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="取餐日期" class="typeInput">
          <el-date-picker
            v-model="time"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="订单编号" class="typeInput">
          <el-input
            v-model="dataForm.orderId"
            placeholder="订单编号"
          ></el-input>
        </el-form-item>

        <el-form-item label="取餐人" class="typeInput">
          <el-input
            v-model="dataForm.consignee"
            placeholder="取餐人"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号码" class="typeInput">
          <el-input v-model="dataForm.mobile" placeholder="手机号码"></el-input>
        </el-form-item>

        <el-form-item lable="订单状态" class="typeInupt">
          <el-select
            v-model="states"
            placeholder="订单状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item lable="订单类型" class="typeInupt">
          <el-select
            v-model="types"
            placeholder="订单类型"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.type"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="付款日期" class="typeInput">
          <el-date-picker
            v-model="payTime"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="dataList" border stripe ref="dataList">
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="订单编号"
      >
      </el-table-column>

      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="商户订单号"
      >
      </el-table-column>

      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>

      <el-table-column
        prop="consignee"
        header-align="center"
        align="center"
        label="取餐人"
      >
      </el-table-column>

      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号码"
      >
      </el-table-column>

      <el-table-column
        prop="freezerName"
        header-align="center"
        align="center"
        label="智能取餐柜名称"
      >
      </el-table-column>

      <el-table-column
        prop="freezerSn"
        header-align="center"
        align="center"
        label="智能取餐柜编号"
      >
      </el-table-column>

      <el-table-column
        prop="periodFullName"
        header-align="center"
        align="center"
        label="时段"
      >
      </el-table-column>

      <el-table-column
        prop="pickupDate"
        header-align="center"
        align="center"
        label="取餐日期"
      >
      </el-table-column>

      <el-table-column
        prop="typeText"
        header-align="center"
        align="center"
        label="订单类型"
      >
      </el-table-column>

      <el-table-column
        prop="stateText"
        header-align="center"
        align="center"
        label="订单状态"
      >
      </el-table-column>

      <el-table-column
        prop="goodSn"
        header-align="center"
        align="center"
        label="商品编号"
      >
      </el-table-column>

      <el-table-column
        prop="categoryName"
        header-align="center"
        align="center"
        label="商品分类"
      >
      </el-table-column>

      <el-table-column
        prop="packageCombo"
        header-align="center"
        align="center"
        label="套餐组合"
      >
      </el-table-column>

      <el-table-column
        prop="packagePrice"
        header-align="center"
        align="center"
        label="套餐价格"
      >
      </el-table-column>

      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="套餐搭配价格"
      >
      </el-table-column>

      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="商品总价"
      >
      </el-table-column>

      <el-table-column
        prop="quantity"
        header-align="center"
        align="center"
        label="商品数量"
      >
      </el-table-column>

      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="订单总额"
      >
      </el-table-column>

      <el-table-column
        prop="actualPrice"
        header-align="center"
        align="center"
        label="实付金额"
      >
      </el-table-column>

      <el-table-column
        prop="packagePrice"
        header-align="center"
        align="center"
        label="支付渠道"
      >
      </el-table-column>

      <el-table-column
        prop="packagePrice"
        header-align="center"
        align="center"
        label="下单日期"
      >
      </el-table-column>

      <el-table-column
        prop="packagePrice"
        header-align="center"
        align="center"
        label="付款日期"
      >
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import {
  getCompanyList,
  getPeriodList,
  getFreezerList,
  getOrgList,
} from '@/utils/options.js';

export default {
  mixins: [listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        freezerId: '',
        pickupDateRang: '',
        paidAtDatRang: '',
        consignee: '',
        mobile: '',
        state: '',
        type: '',
      },
      orgList: [],
      companyList: [],
      periodList: [],
      freezerList: [],
      time: [],
      payTime: [],
      states: [],
      types: [],
      stateList: [
        { id: 0, state: '待付款' },
        { id: 130, state: '已取消' },
        { id: 128, state: '已过期' },
        { id: 1, state: '已付款' },
        { id: 131, state: '已退款' },
        { id: 5, state: '待配餐' },
        { id: 129, state: '已完成' },
      ],
      typeList: [
        { id: 0, type: '现售' },
        { id: 1, type: '预售' },
        { id: 2, type: '秒杀' },
        { id: 3, type: '现售预订' },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {},
  activated() {
    this.getOrgList();
    this.getCompanyList();
    this.getPeriodList();
    this.getFreezerList();
    this.getDataList(1);
  },
  methods: {
    getDataList(page) {
      if (this.time?.length) {
        this.dataForm.pickupDateRang = this.time.join(',');
      }
      if (this.payTime?.length) {
        this.dataForm.paidAtDatRang = this.payTime.join(',');
      }
      this.dataForm.state = this.states.join(',');
      this.dataForm.type = this.types.join(',');

      this.$http({
        url: '/cc/order/param/page',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          perPage: this.pageSize,
          ...this.dataForm,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.totalPage = data.data.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    //智能取餐柜列表
    getFreezerList() {
      getFreezerList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
  },
};
</script>
